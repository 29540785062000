import {Component, Input, OnDestroy, OnInit, TemplateRef} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {StoreProfile} from '../../domain/models/store/store-profile';
import {StoreService} from '../../domain/store.service';
import {OrderService} from '../../domain/order.service';
import {CustomerService} from '../../domain/customer.service';
import {Store} from '../../domain/models/store/store';
import {Subscription} from 'rxjs';
import {delay} from '../../utils/promise.utils';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.sass'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @Input() store?: Store;
  @Input() profile?: StoreProfile;
  @Input() title: string | undefined;
  @Input() image: string | undefined;
  @Input() subtitle: string | undefined;
  @Input() hasMenu: boolean = true;
  @Input() action?: TemplateRef<any>;
  @Input() showBackButton = false;

  isMenuOpen = false;
  showBadge = false;

  isSignedInSubscription?: Subscription;

  constructor(private location: Location,
              private router: Router,
              private route: ActivatedRoute,
              private storeService: StoreService,
              private orderService: OrderService,
              private customerService: CustomerService,
  ) {
  }

  ngOnInit(): void {
    this.isMenuOpen = this.route.snapshot.queryParams['menu'];
    this.isSignedInSubscription = this.customerService.isSignedIn$.subscribe(() => {
      // Wait next tick
      delay(100).then(() => this.showBadge = this.store ? this.orderService.hasRecentPurchase(this.store.storeChain.id) : false);
    });
  }

  async onBackClick() {
    if (this.router.url.includes('/orders/')) {
      await this.router.navigate(['..'], {relativeTo: this.route, queryParamsHandling: 'preserve'});
      return;
    }

    const backCtx = this.route.snapshot.queryParams['ctx'];

    if (backCtx && backCtx.length > 0) {
      await this.router.navigate([backCtx]);
      return;
    }

    await this.router.navigate(['..'], {relativeTo: this.route});
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.storeService.contextHistoryPath = this.router.url.split('?')[0];
    const url = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: {menu: this.isMenuOpen ? 'visible' : undefined},
      queryParamsHandling: 'merge',
    }).toString();
    this.location.go(url);
  }

  ngOnDestroy() {
    this.isSignedInSubscription?.unsubscribe();
  }
}

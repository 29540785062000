import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {CustomerService} from '../../domain/customer.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from 'ngx-toastr';
import {OrderService} from '../../domain/order.service';
import {StoreService} from '../../domain/store.service';
import {Router} from '@angular/router';
import {Store} from '../../domain/models/store/store';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.sass'],
})
export class MenuComponent implements OnInit, OnChanges, OnDestroy {
  @Input() store?: Store;
  @Output() signOut = new EventEmitter();
  showMyOrdersBadge = false;
  loginEnabled = false;
  isSignedInSubscription?: Subscription;

  constructor(public customerService: CustomerService,
              private translateService: TranslateService,
              private toastr: ToastrService,
              private orderService: OrderService,
              private storeService: StoreService,
              private router: Router,
  ) {
  }

  ngOnInit() {
    this.storeService.contextHistoryPath = this.router.url.split('?')[0];
    this.isSignedInSubscription = this.customerService.isSignedIn$.subscribe(() => {
      this.showMyOrdersBadge = this.store ? this.orderService.hasRecentPurchase(this.store.storeChain.id) : false;
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.store) {
      await this.checkLoginMethods();
    }
  }

  private async checkLoginMethods() {
    if (this.store) {
      const loginMethods = await this.storeService.getLoginMethods(this.store.handle);
      this.loginEnabled = loginMethods && loginMethods.length > 0;
    }
  }

  async logout($event: MouseEvent) {
    $event.preventDefault();

    if (!this.store) {
      return;
    }

    this.customerService.logout(this.store.storeChain.id);

    const message = await this.translateService.get('LOGIN.loggedOut').toPromise();
    this.toastr.success(
      message,
      undefined,
      {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'},
    );

    this.signOut.emit();
  }

  async login() {
    if (!this.store) {
      return;
    }

    this.customerService.openLoginDialog(this.store.storeChain.id);
  }

  ngOnDestroy() {
    this.isSignedInSubscription?.unsubscribe();
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Order} from '../../../domain/models/order/order';
import {OrderService} from '../../../domain/order.service';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {Store} from '../../../domain/models/store/store';
import {StoreService} from '../../../domain/store.service';
import {OrderUtils} from '../../../utils/order.utils';
import {StoreProfile} from '../../../domain/models/store/store-profile';
import {CustomerService} from '../../../domain/customer.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.sass']
})
export class OrderDetailsComponent implements OnInit {
  order?: Order;
  store!: Store;
  profile?: StoreProfile;
  isChrome = /chrome/i.test(navigator.userAgent);
  showControl = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private orderService: OrderService,
              private storeService: StoreService,
              private translateService: TranslateService,
              private titleService: Title,
              private customerService: CustomerService,
  ) {
  }

  async ngOnInit() {
    await this.storeService.preserveContextInUrl();

    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.store = await this.storeService.getStore(storeHandle);
    this.profile = this.store.storeProfile;
    this.customerService.initCustomerFromAuth(this.store.storeChain.id);

    const orderId = this.route.snapshot.paramMap.get('orderId')!;
    this.order = await this.orderService.getOrder(orderId);

    this.translateService.get('ORDERS.purchase').toPromise()
      .then(title => this.titleService.setTitle(`${title} | FYGI`));

    this.checkControl();
  }

  checkControl() {
    const maxAge = 60 * 60 * 1000 * 6; // 6 hours

    if (!this.order || !this.store) {
      this.showControl = false;
      return;
    }

    const paidAtDate = OrderUtils.getPaidAtDate(this.order);
    const timeExpired = (paidAtDate?.getTime() ?? 0) + maxAge;

    if (paidAtDate && (timeExpired > Date.now() || OrderUtils.isPendingDelivery(this.order))) {
      this.showControl = true;
    }
  }

  async goToReceiptPage() {
    await this.router.navigate([`/store/${this.store?.handle}/receipt/${this.order?.id}`], {queryParamsHandling: 'preserve'});
  }
}

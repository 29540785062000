import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AppConfig} from '../app.config';
import {CustomerSmsLoginResponse} from './models/customer/customer-sms-login.response';
import {CustomerSmsAuthenticateResponse} from './models/customer/customer-sms-authenticate.response';
import {CustomerAnonymousAuthenticateResponse} from './models/customer/customer-anonymous-authenicate.response';
import {CustomerGoogleAuthenticateResponse} from './models/customer/customer-google-authenticate.response';
import {CustomerVippsInitializeResponse} from './models/customer/customer-vipps-initialize.response';
import {CustomerVippsAuthenticateResponse} from './models/customer/customer-vipps-authenticate.response';

@Injectable({
  providedIn: 'root',
})
export class CustomerProvider {

  constructor(private http: HttpClient) {
  }

  createAnonymousCustomer(storeChainId: string): Observable<string> {
    return this.http.post<string>(`${AppConfig.getApiUrl('v1')}/customers/anonymous`,
      {
        storeChainId,
      },
      {
        headers: {'accept': 'application/vnd.fygi.v1.id+json'},
      },
    );
  }

  authenticateAnonymousCustomer(storeChainId: string, accountId: string) {
    return this.http.post<CustomerAnonymousAuthenticateResponse>(`${AppConfig.getApiUrl('v1')}/customers/anonymous/authenticate`,
      {
        storeChainId,
        accountId,
      },
      {
        headers: {'accept': 'application/vnd.fygi.v1.id+json'},
      },
    );
  }

  customerSmsLogin(storeChainId: string, phoneNumber: string) {
    return this.http.post<CustomerSmsLoginResponse>(`${AppConfig.getApiUrl('v1')}/customers/sms`,
      {
        storeChainId,
        phoneNumber,
      },
      {
        headers: {'accept': 'application/vnd.fygi.v1.id+json'},
      },
    );
  }

  customerSmsAuthenticate(storeChainId: string, phoneNumber: string, smsConfirmationCode: string) {
    return this.http.post<CustomerSmsAuthenticateResponse>(`${AppConfig.getApiUrl('v1')}/customers/sms/authenticate`,
      {
        storeChainId,
        phoneNumber,
        smsConfirmationCode,
      },
      {
        headers: {'accept': 'application/vnd.fygi.v1.id+json'},
      },
    );
  }

  customerGoogleAuthenticate(storeChainId: string, googleJwt: string) {
    return this.http.post<CustomerGoogleAuthenticateResponse>(`${AppConfig.getApiUrl('v1')}/customers/google/authenticate`,
      {
        storeChainId,
        googleJwt,
      },
      {
        headers: {'accept': 'application/vnd.fygi.v1.id+json'},
      },
    );
  }

  customerVippsInitialize(storeChainId: string, state: string) {
    return this.http.post<CustomerVippsInitializeResponse>(`${AppConfig.getApiUrl('v1')}/customers/vipps/initialize`,
      {
        storeChainId,
        state,
      },
      {
        headers: {'accept': 'application/vnd.fygi.v1.id+json'},
      },
    );
  }

  customerVippsAuthenticate(storeChainId: string, code: string) {
    return this.http.post<CustomerVippsAuthenticateResponse>(`${AppConfig.getApiUrl('v1')}/customers/vipps/authenticate`,
      {
        storeChainId,
        code,
      },
      {
        headers: {'accept': 'application/vnd.fygi.v1.id+json'},
      },
    );
  }
}

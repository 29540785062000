import {Component, OnDestroy, OnInit} from '@angular/core';
import {Order} from '../../../domain/models/order/order';
import {Store} from '../../../domain/models/store/store';
import {ActivatedRoute, Router} from '@angular/router';
import {OrderService} from '../../../domain/order.service';
import {StoreService} from '../../../domain/store.service';
import {TranslateService} from '@ngx-translate/core';
import {CustomerService} from '../../../domain/customer.service';
import {StoreProfile} from '../../../domain/models/store/store-profile';
import {delay} from '../../../utils/promise.utils';
import {Product} from '../../../domain/models/product/product';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CartService} from '../../../domain/cart.service';
import {Title} from '@angular/platform-browser';
import {ForegroundPaths} from '../../../app-routing.module';
import {isPaid, OrderUtils} from '../../../utils/order.utils';
import {PaymentService} from '../../../domain/payment.service';
import {GateCode} from '../../../domain/models/order/gate-code';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmDeliveryDialog} from '../../../dialogs/confirm-delivery/confirm-delivery.dialog';
import {ToastrService} from 'ngx-toastr';
import {TransactionStatus, TransactionType} from '../../../domain/models/order/transaction';
import Bugsnag from '@bugsnag/js';
import {decodeJwt} from 'jose';
import {Subscription} from 'rxjs';
import {skip} from 'rxjs/operators';
import {OrderLineType, ProductOrderLine} from '../../../domain/models/order/order-line';

@Component({
  selector: 'app-receipt-page',
  templateUrl: './receipt-page.component.html',
  styleUrls: ['./receipt-page.component.sass'],
  animations: [
    trigger('tabState', [
      state('default',
        style({right: '-400px'}),
      ),
      state('open',
        style({right: '0px'}),
      ),
      transition('* => open', animate(300)),
    ])],
})
export class ReceiptPageComponent implements OnInit, OnDestroy {
  order?: Order;
  productOrderLines?: ProductOrderLine[];
  orderId: string | undefined;
  store!: Store;
  profile?: StoreProfile;
  fadeout = false;
  transactionId: string | null | undefined;
  isPaid = false;
  isPendingDelivery = false;
  isDelivered = false;
  deliveredAtString?: string;
  getOrderPaymentAttempts = 0;
  maxOrderPaymentAttempts = 25;
  currencyCode: string | undefined;
  cultureName: string | undefined;
  product?: Product;
  failed = false;
  orderTime?: number;
  state = 'default';
  enableSignIn = false;
  isChrome = /chrome/i.test(navigator.userAgent);

  countdownString?: string;
  intervalRef?: number;

  refreshPaymentStatusRef: (() => Promise<void>) | null = null;
  redirectBrowser = false;
  enableGateSettings = false;
  storeGate?: GateCode;
  awaitLoginSubscription?: Subscription;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private orderService: OrderService,
              private storeService: StoreService,
              private translateService: TranslateService,
              public customerService: CustomerService,
              private paymentService: PaymentService,
              private cartService: CartService,
              private titleService: Title,
              private dialog: MatDialog,
              private toastr: ToastrService,
  ) {
  }

  async ngOnInit() {
    this.translateService.get('ORDERS.purchase').toPromise().then(title => this.titleService.setTitle(`${title} | FYGI`));
    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.store = await this.storeService.getStore(storeHandle);
    this.customerService.initCustomerFromAuth(this.store.storeChain.id);

    const tempToken = this.route.snapshot.queryParams['tempToken'];
    if (tempToken?.length > 0) {
      await this.handleTempTokenFromRedirect(tempToken);
    }

    this.orderId = this.route.snapshot.paramMap.get('orderId')!;
    this.transactionId = this.route.snapshot.paramMap.get('transactionId');
    this.profile = this.store.storeProfile;
    this.currencyCode = this.store.currencyCode;
    this.cultureName = this.store.cultureName;


    await Promise.allSettled([
      this.checkGateSettings(),
      this.checkLoginMethods(),
    ]);

    // Using method ref. in order to prevent self referencing after ngOnDestroy
    this.refreshPaymentStatusRef = this.refreshPaymentStatus.bind(this);
    await this.refreshPaymentStatusRef();
  }

  private async checkLoginMethods() {
    if (this.store) {
      const loginMethods = await this.storeService.getLoginMethods(this.store.handle);
      this.enableSignIn = loginMethods && loginMethods.length > 0;
    }
  }

  private async checkGateSettings() {
    if (this.store) {
      try {
        this.enableGateSettings = await this.storeService.isGateEnabled(this.store.handle);
      } catch (error) {
        if (error.status == 404) {
          this.enableGateSettings = false;
        } else {
          throw error;
        }
      }
      await this.setupGateCode();
    }
  }

  private async setupGateCode() {
    if (this.enableGateSettings && this.orderId) {
      try {
        this.storeGate = await this.orderService.getOrderGateCode(this.orderId);
      } catch (error) {
        if (error.status == 404 || error.status == 400) {
          this.enableGateSettings = false;
        } else {
          throw error;
        }
      }
    }
  }

  ngOnDestroy() {
    this.refreshPaymentStatusRef = null;
    window.clearInterval(this.intervalRef);
    this.awaitLoginSubscription?.unsubscribe();
  }

  async paymentSuccess() {
    Bugsnag.leaveBreadcrumb('Payment success');

    this.order = await this.orderService.getOrder(this.orderId!);
    this.productOrderLines = this.order.orderLines.filter(line => line.type === OrderLineType.Product) as ProductOrderLine[];
    this.isDelivered = OrderUtils.isDelivered(this.order);

    if (this.isDelivered) {
      this.isPendingDelivery = false;
      this.setDeliveredAtString();
    } else {
      this.isPendingDelivery = OrderUtils.isPendingDelivery(this.order);
    }

    this.getTimer();
    this.intervalRef = window.setInterval(this.getTimer.bind(this), 1000);

    if (this.orderTime) {
      this.orderService.setMostRecentPurchase(this.store.storeChain.id, this.orderTime);

      if (Date.now() - this.orderTime < 60 * 1000 * 5) {
        this.fadeout = true;
        await delay(1500);
      }
    }

    this.fadeout = false;
    this.state = 'open';
  }

  private async refreshPaymentStatus() {
    if (this.order == undefined) {
      this.order = await this.orderService.getOrder(this.orderId!).catch(error => {
        if (error.status == 401 || error.status == 404) {
          this.refreshPaymentStatusRef = null;
          this.redirectBrowser = true;
          this.failed = true;

          Bugsnag.notify({
            name: `Post payment redirect browser (${error.status})`,
            message: 'Please return to the browser where you initiated the purchase',
          });

          if (error.status == 401 && (this.awaitLoginSubscription?.closed ?? true)) {
            this.awaitLoginSubscription = this.customerService.isSignedIn$
              .pipe(skip(1))
              .subscribe(async value => {
                if (value) {
                  Bugsnag.leaveBreadcrumb('User re-authenticated on refresh payment status');
                  this.awaitLoginSubscription?.unsubscribe();
                  this.retry();
                }
              });
          }

          return;
        }

        if (error.error?.title && error.error?.detail) {
          Bugsnag.notify(
            {name: 'Refresh payment status failed', message: `${error.error.detail}: ${error.error.title}`},
            event => event.addMetadata('Response', error),
          );
        }

        this.failed = true;
        return undefined;
      });

      if (this.order === undefined) {
        return;
      }
      this.productOrderLines = this.order.orderLines.filter(line => line.type === OrderLineType.Product) as ProductOrderLine[];
    }

    const transaction = await this.paymentService.refreshPaymentStatus(this.order.id);

    if (transaction.paymentMethod === 'mastercard' || transaction.paymentMethod === 'checkout') {
      await this.paymentService.complete(this.order.id, this.transactionId!);
    }

    this.getOrderPaymentAttempts++;
    this.isPaid = isPaid(transaction);

    if (!this.isPaid) {
      if (this.getOrderPaymentAttempts <= this.maxOrderPaymentAttempts) {
        await delay(1000);

        if (this.refreshPaymentStatusRef) {
          await this.refreshPaymentStatusRef();
        }
      } else {
        this.failed = true;
        Bugsnag.notify({
          name: 'Payment failed after max attempts',
          message: `Payment failed after max attempts: ${this.maxOrderPaymentAttempts}`,
        });
      }
    } else if (this.isPaid) {
      await this.paymentSuccess();

      // TODO We should only clear cart if the current cart has the same ID
      this.cartService.clearCart(this.store.handle);
      this.orderService.clearOrdersInspected();
    }
  }

  async showReceipt() {
    this.storeService.contextHistoryPath = this.router.url.split('/receipt/')[0];
    await this.router.navigate(ForegroundPaths.empty());
    await this.router.navigate([`/store/${this.store.handle}/orders/${this.order?.id}`]);
  }

  openSignIn() {
    if (this.order != null) {
      this.orderService.setLastKnownOrder(this.order.id);
    }
    this.customerService.openLoginDialog(this.store.storeChain.id);
  }

  async closeForeground() {
    await this.router.navigate(ForegroundPaths.empty());
    await this.router.navigate([`store/${this.store.handle}/browse`]);
  }

  getTimer() {
    if (this.order != undefined && this.orderTime == undefined) {
      this.orderTime = OrderUtils.getPaidAtDate(this.order)?.getTime() ?? undefined;
    }

    if (this.orderTime != undefined) {
      const timer = Date.now() - this.orderTime;
      const orderSec = Math.floor(timer / 1000) % 60;
      const orderMin = Math.floor(Math.floor(timer / 1000 / 60) % 60);
      const orderHour = Math.floor(Math.floor(timer / 1000 / 60 / 60) % 60);

      if (orderHour >= 1) {
        this.countdownString = `${orderHour}:${orderMin}:${orderSec}`;

        return;
      } else if (orderSec < 10) {
        this.countdownString = `${orderMin}:0${orderSec}`;

        return;
      }

      this.countdownString = `${orderMin}:${orderSec}`;

      return;
    }

    this.countdownString = 'Expired';
  }

  cancelPayment() {
    this.router.navigate([`/store/${this.store.handle}`]).then(async () => {
      this.router.navigate(ForegroundPaths.paymentFailed(), {skipLocationChange: true});
    });
  }

  retry() {
    this.redirectBrowser = false;
    this.isPaid = false;
    this.failed = false;
    this.getOrderPaymentAttempts = 0;
    this.refreshPaymentStatusRef = this.refreshPaymentStatus.bind(this);
    this.refreshPaymentStatusRef();
  }

  async setDeliveredAtString() {
    if (this.order != undefined) {
      const transactions = this.order.transactions
        .filter(t => t.type === TransactionType.Delivery)
        .filter(t => t.status === TransactionStatus.Success);

      const date = new Date(`${transactions[transactions.length - 1].createdAt}Z`);

      this.deliveredAtString = `
        ${date.toLocaleTimeString().replace(/\s/g, '&nbsp;')},
        ${date.toLocaleString(undefined, {year: 'numeric', month: 'long', day: 'numeric'}).replace(/\s/g, '&nbsp;')}
     `;
    }
  }

  async confirmDelivered() {
    const dialogRef = this.dialog.open(
      ConfirmDeliveryDialog,
      {width: '330px', data: {profile: this.profile}},
    );
    const result = await dialogRef.afterClosed().toPromise();

    if (result === true) {
      if (!this.order) {
        throw new Error('Order is not defined; cannot set order as delivered');
      }

      try {
        await this.orderService.setOrderDelivered(this.order.id);
      } catch (error) {
        if (error?.status == 400 && error?.error?.detail?.includes('is already delivered')) {
          this.toastr.clear(); // Suppress error warning TODO Handled 400s should be exclude-able from the intercept error handler
        } else {
          throw error;
        }
      }

      this.state = 'default';

      this.order = await this.orderService.getOrder(this.orderId!);
      this.productOrderLines = this.order.orderLines.filter(line => line.type === OrderLineType.Product) as ProductOrderLine[];
      this.setDeliveredAtString();
      this.isDelivered = true;
      this.isPendingDelivery = false;

      this.state = 'open';

      return true;
    }

    return false;
  }

  async handleTempTokenFromRedirect(tempToken: string) {
    const existingToken = this.customerService.getAndValidateToken(this.store.storeChain.id);
    if (typeof existingToken === 'string') {
      // Customer already has as session. Make sure we don't replace a longer lived token with a shorter one
      const existingTokenData = decodeJwt(existingToken);
      const tempTokenData = decodeJwt(tempToken);
      if (existingTokenData.CustomerId != tempTokenData.CustomerId || (tempTokenData.exp ?? 0) > (existingTokenData.exp ?? 0)) {
        await this.customerService.login(this.store.storeChain.id, tempToken);
        Bugsnag.leaveBreadcrumb('Logged in with temp token, replacing existing session');
      }
    } else {
      await this.customerService.login(this.store.storeChain.id, tempToken);
      Bugsnag.leaveBreadcrumb('Logged in with temp token');
    }
    await this.router.navigate([], {
      queryParams: {
        tempToken: null,
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }
}

import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {EmailReceiptResponse} from './models/email/email-receipt.response';
import {AppConfig} from '../app.config';
import {EmailReceiptRequest} from './models/email/email-receipt.request';

@Injectable({
  providedIn: 'root',
})
export class EmailProvider {

  constructor(private http: HttpClient) {
  }

  sendReceipt(request: EmailReceiptRequest): Observable<EmailReceiptResponse> {
    return this.http.post<EmailReceiptResponse>(`${AppConfig.getApiUrl()}/orders/receipt`,
      request, {
        headers: {
          'auth-key': AppConfig.authKey,
        },
      });
  }

  sendGiftReceipt(request: EmailReceiptRequest) {
    return this.http.post<EmailReceiptResponse>(`${AppConfig.getApiUrl()}/orders/gift-receipt`,
      request, {
        headers: {
          'auth-key': AppConfig.authKey,
        },
      });
  }
}

import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild} from '@angular/core';
import {CustomerService} from '../../domain/customer.service';
import {LoadJsFilesService} from '../../domain/load-js-files.service';

@Component({
  selector: 'app-google-sign-in[storeChainId]',
  templateUrl: './google-sign-in.component.html',
  styleUrls: ['./google-sign-in.component.sass'],
})
export class GoogleSignInComponent implements OnInit, AfterViewInit {
  @Input('storeChainId') storeChainId!: string;
  @Output() loggedIn = new EventEmitter();
  @Output() isLoading = new EventEmitter<boolean>();
  @ViewChild('btn') btn?: ElementRef;
  google?: GoogleSignInClient;
  readonly googleClientJsFile = 'https://accounts.google.com/gsi/client';

  constructor(private el: ElementRef,
              private customerService: CustomerService,
              private loadJsFilesService: LoadJsFilesService,
              private renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {
    this.loadJsFilesService.loadJsScript(this.renderer, this.googleClientJsFile)
      .then(() => {
        this.google = (window as any).google;
        this.ngAfterViewInit();
      })
      .catch(() => {
        throw new Error('Google SignInClient library not loaded');
      });
  }

  ngAfterViewInit(): void {
    // Store a reference on Window in order for Cypress to run tests
    (window as any).googleSingInCallbackHandler = this.handleCredentialResponse.bind(this);

    this.google?.accounts.id.initialize({
      client_id: '414380604042-5195npf2j0snbrjjk413c7smtgnmdqqb.apps.googleusercontent.com',
      callback: (window as any).googleSingInCallbackHandler,
    });
    this.google?.accounts.id.renderButton(
      this.btn?.nativeElement,
      {
        type: 'standard',
        theme: 'filled_blue',
        size: 'large',
        text: 'signup_with',
        shape: 'rectangular',
        width: this.el.nativeElement.clientWidth,
        locale: navigator.language,
      },
    );
  }

  async handleCredentialResponse(response: { credential: string }) {
    this.isLoading.emit(true);
    await this.customerService
      .customerGoogleAuthenticate(this.storeChainId, response.credential)
      .finally(() => this.isLoading.emit(false));
    this.loggedIn.emit();
  }
}

interface GoogleSignInClient {
  accounts: {
    id: {
      initialize(params: object): void,
      renderButton(el: ElementRef, params: object): void,
    },
  },
}

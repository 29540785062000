import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {AppRoutingModule, ForegroundUrlSerializer} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateCompiler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateMessageFormatCompiler} from 'ngx-translate-messageformat-compiler';
import {GradientLoaderComponent} from './components/gradient-loader/gradient-loader.component';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatIconModule} from '@angular/material/icon';
import {StoreDetailsComponent} from './pages/background/store-details/store-details.component';
import {ProductCardComponent} from './components/product-card/product-card.component';
import {ImageFadingComponent} from './components/image-fading/image-fading.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ExpandingOverlayCardComponent} from './components/expanding-overlay-card/expanding-overlay-card.component';
import {CartComponent} from './pages/foreground/cart/cart.component';
import {ProductDetailsComponent} from './pages/foreground/product-details/product-details.component';
import {SwiperModule} from 'swiper/angular';
import {SafeHtmlPipe} from './utils/pipes/safehtml.pipe';
import {SelectOptionDialog} from './dialogs/select-option/select-option.dialog';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {CartDetailsComponent} from './pages/foreground/cart-details/cart-details.component';
import {CartItemComponent} from './components/cart-item/cart-item.component';
import {QuantitySelectorComponent} from './components/quantity-selector/quantity-selector.component';
import {AsPipe} from './utils/pipes/as.pipe';
import {ConfirmDialog} from './dialogs/confirm/confirm.dialog';
import {ConfirmDeliveryDialog} from './dialogs/confirm-delivery/confirm-delivery.dialog';
import {SearchBarComponent} from './components/search-bar/search-bar.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProductItemComponent} from './components/product-item/product-item.component';
import {LottieAnimationViewModule} from 'ng-lottie';
import {LottieModule} from 'ngx-lottie';
import player from 'lottie-web';
import {EmailDialog} from './dialogs/email/email.dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {OrderListComponent} from './pages/background/order-list/order-list.component';
import {OrderItemComponent} from './components/order-item/order-item.component';
import {OrderDetailsComponent} from './pages/background/order-details/order-details.component';
import {ReceiptComponent} from './components/receipt/receipt.component';
import {ReceiptEmailButtonComponent} from './components/receipt-email-button/receipt-email-button.component';
import {MenuComponent} from './components/menu/menu.component';
import {ScannerComponent} from './components/scanner/scanner.component';
import {PrivacyBannerComponent} from './components/privacy-banner/privacy-banner.component';
import {UrlSerializer} from '@angular/router';
import {ToastrModule} from 'ngx-toastr';
import {ScanWeightForOrderLineComponent} from './pages/foreground/scan-weight-for-orderline/scan-weight-for-orderline.component';
import {PrivacyPolicyComponent} from './pages/background/privacy-policy/privacy-policy.component';
import {EanInputDialog} from './dialogs/ean-input/ean-input.dialog';
import {QRCodeModule} from 'angularx-qrcode';
import {PrivacyDialog} from './dialogs/privacy/privacy.dialog';
import {AmountsheetDialog} from './dialogs/actionsheet/amountsheet/amountsheet.dialog';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {SelectFulfilmentDialog} from './dialogs/select-fulfilment/select-fulfillment-dialog';
import {ShipmentsheetDialog} from './dialogs/actionsheet/shipmentsheet/shipmentsheet.dialog';
import {AddressFormComponent} from './components/adress-form/address-form.component';
import {ShoppingBagComponent} from './pages/foreground/shopping-bag/shopping-bag.component';
import {AdjustmentTypeDialog} from './dialogs/adjustment-type/adjustment-type-dialog';
import {AdjustmentTypeChooserComponent} from './pages/foreground/adjustment-type-chooser/adjustment-type-chooser.component';
import localeSe from '@angular/common/locales/sv';
import localeNb from '@angular/common/locales/nb';
import localeDe from '@angular/common/locales/de';
import {NgxBarcode6Module} from 'ngx-barcode6';
import {OfflineCartItemComponent} from './components/offline-cart-item/offline-cart-item.component';
import {OfflineDialogComponent} from './dialogs/offline-dialog/offline-dialog.component';
import {QuantityConfirmDialog} from './dialogs/quantity-confirm/quantity-confirm.dialog';
import {PaymentFailedComponent} from './pages/foreground/payment-failed/payment-failed.component';
import {CategoryItemComponent} from './pages/background/store-details/category-item/category-item.component';
import {AgeLimitComponent} from './pages/foreground/age-limit/age-limit.component';
import {MessageDialog} from './dialogs/message/message.dialog';
import {CustomHttpInterceptor} from './custom-http-interceptor';
import {GooglePayComponent} from './components/google-pay/google-pay.component';
import {VrPayComponent} from './components/vr-pay/vr-pay.component';
import {GooglePayButtonModule} from '@google-pay/button-angular';
import {CartPaymentComponent} from './pages/foreground/cart-payment/cart-payment.component';
import {MatBadgeModule} from '@angular/material/badge';
import {CartBundleItemComponent} from './components/cart-bundle-item/cart-bundle-item.component';
import {SelectProductDimension} from './dialogs/select-product-dimension/select-product-dimension.dialog';
import {ApplePayComponent} from './components/apple-pay/apple-pay.component';
import {BehindCounterMenuComponent} from './pages/foreground/behind-counter-menu/behind-counter-menu.component';
import {LoginComponent} from './components/login/login.component';
import {LoginDialog} from './dialogs/login-dialog/login.dialog';
import {GoogleSignInComponent} from './components/google-sign-in/google-sign-in.component';
import {MatSelectModule} from '@angular/material/select';
import {VippsAuthComponent} from './pages/background/vipps-auth/vipps-auth.component';
import {VippsLoginComponent} from './components/vipps-login/vipps-login.component';
import {registerLocaleData} from '@angular/common';
import {BugsnagErrorHandler} from '@bugsnag/plugin-angular';
import {ScrollSpyDirective} from './directives/scroll-spy.directive';
import {SafeResourcePipe} from './utils/pipes/safeResource.pipe';
import {ReceiptPageComponent} from './pages/background/receipt-page/receipt-page.component';
import {ReceiptProductImageComponent} from './components/receipt-product-image/receipt-product-image.component';
import {ScrollShadowDirective} from './directives/scroll-shadow.directive';
import {FygiPageComponent} from './pages/background/fygi-page/fygi-page.component';
import {ThrottleClickDirective} from './directives/throttle-click.directive';
import {InvalidStoreDialog} from './dialogs/invalid-store/invalid-store.dialog';
import {LoadingComponent} from './components/loading/loading.component';
import {InvalidMicroshopDialog} from './dialogs/invalid-microshop/invalid-microshop.dialog';
import {SlideToConfirmComponent} from './components/slide-to-confirm/slide-to-confirm.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {bugsnagInit} from './bugsnag';
import {KebabFridayComponent} from './pages/foreground/easter-eggs/kebab-friday/kebab-friday.component';
import {DoorComponent} from './pages/background/door/door.component';
import {PageNotFoundComponent} from './pages/background/page-not-found/page-not-found.component';
import {InspectionComponent} from './pages/foreground/inspection/inspection.component';
import {CashPaymentComponent} from './pages/foreground/cash-payment/cash-payment.component';
import {DoorClosedDialog} from './dialogs/door-closed/door-closed.dialog';
import {SoftpayPaymentComponent} from './pages/foreground/softpay-payment/softpay-payment.component';

const APP_VERSION = '25.0';

bugsnagInit(APP_VERSION);

registerLocaleData(localeSe);
registerLocaleData(localeNb);
registerLocaleData(localeDe);

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    GradientLoaderComponent,
    ToolbarComponent,
    StoreDetailsComponent,
    ProductCardComponent,
    ImageFadingComponent,
    ExpandingOverlayCardComponent,
    CartComponent,
    ProductDetailsComponent,
    CartItemComponent,
    OfflineCartItemComponent,
    QuantitySelectorComponent,
    CartDetailsComponent,
    SafeHtmlPipe,
    SafeResourcePipe,
    AsPipe,
    SelectOptionDialog,
    ConfirmDialog,
    ConfirmDeliveryDialog,
    SearchBarComponent,
    ProductItemComponent,
    EmailDialog,
    OrderListComponent,
    OrderItemComponent,
    OrderDetailsComponent,
    ReceiptComponent,
    ReceiptEmailButtonComponent,
    MenuComponent,
    ScannerComponent,
    PrivacyBannerComponent,
    ScanWeightForOrderLineComponent,
    EanInputDialog,
    MessageDialog,
    PrivacyDialog,
    PrivacyPolicyComponent,
    AmountsheetDialog,
    SelectFulfilmentDialog,
    AdjustmentTypeDialog,
    ShipmentsheetDialog,
    AddressFormComponent,
    ShoppingBagComponent,
    AdjustmentTypeChooserComponent,
    OfflineCartItemComponent,
    OfflineDialogComponent,
    QuantityConfirmDialog,
    PaymentFailedComponent,
    CategoryItemComponent,
    ApplePayComponent,
    GooglePayComponent,
    VrPayComponent,
    AgeLimitComponent,
    CartPaymentComponent,
    CartBundleItemComponent,
    SelectProductDimension,
    BehindCounterMenuComponent,
    LoginComponent,
    LoginDialog,
    GoogleSignInComponent,
    VippsAuthComponent,
    VippsLoginComponent,
    ScrollSpyDirective,
    ScrollShadowDirective,
    ReceiptPageComponent,
    ReceiptProductImageComponent,
    FygiPageComponent,
    ThrottleClickDirective,
    InvalidStoreDialog,
    DoorClosedDialog,
    InvalidMicroshopDialog,
    LoadingComponent,
    SlideToConfirmComponent,
    KebabFridayComponent,
    DoorComponent,
    PageNotFoundComponent,
    InspectionComponent,
    CashPaymentComponent,
    SoftpayPaymentComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatIconModule,
    MatProgressSpinnerModule,
    SwiperModule,
    MatDialogModule,
    BrowserAnimationsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    ToastrModule.forRoot({preventDuplicates: true}),
    NgxWebstorageModule.forRoot(),
    LottieAnimationViewModule.forRoot(),
    LottieModule.forRoot({player: playerFactory}),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
    }),
    QRCodeModule,
    NgxBarcode6Module,
    MatBottomSheetModule,
    ReactiveFormsModule,
    MatBadgeModule,
    GooglePayButtonModule,
    MatSelectModule,
    DragDropModule,
  ],
  providers: [
    MatDialog,
    {provide: UrlSerializer, useClass: ForegroundUrlSerializer},
    {provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true},
    {provide: MatBottomSheetModule, useValue: {}},
    {provide: MAT_BOTTOM_SHEET_DATA, useValue: {}},
    {
      provide: ErrorHandler, useFactory() {
        return new BugsnagErrorHandler();
      },
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  bootstrap: [AppComponent],
})

export class AppModule {
}

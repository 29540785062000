import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StoreProfile} from '../../domain/models/store/store-profile';

@Component({
  templateUrl: './email.dialog.html',
  styleUrls: ['./email.dialog.sass'],
})
export class EmailDialog {
  email?: string;
  profile?: StoreProfile;

  constructor(public dialogRef: MatDialogRef<EmailDialog>,
              @Inject(MAT_DIALOG_DATA) data?: { profile: StoreProfile },
  ) {
    this.profile = data?.profile;
  }

  close(email?: string) {
    this.dialogRef.close(email);
  }
}

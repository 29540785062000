<div>
  <div class="login-dialog-close">
    <mat-icon (click)="reject()">
      close
    </mat-icon>
  </div>
  <div>
    <h2 class="login-dialog-title">{{ 'DIALOG.LOGIN.signIn' | translate }}</h2>
  </div>
  <div class="login-dialog__body">
    <div class="login-dialog-loading" *ngIf="isLoading">
      <mat-spinner diameter="30">{{ 'LOGIN.loading' | translate }}</mat-spinner>
    </div>
    <div *ngIf="enableGoogleLogin" class="login-dialog__app-login-container third-party-login-btn">
      <app-google-sign-in [storeChainId]="store.storeChain.id" (loggedIn)="onLogin()" (isLoading)="onLoading($event)"></app-google-sign-in>
    </div>
    <div *ngIf="enableVippsLogin" class="login-dialog__app-login-container third-party-login-btn">
      <app-vipps-login [storeChainId]="store.storeChain.id" (loggedIn)="onLogin()" (isLoading)="onLoading($event)"></app-vipps-login>
    </div>
    <div *ngIf="showNoLoginMethods" class="login-dialog-message">
      {{ 'DIALOG.LOGIN.noLoginMethodsError' | translate }}
    </div>
    <div class="login-dialog__spacer-title" *ngIf="(enableVippsLogin || enableGoogleLogin) && enablePhoneLogin">
      <div class="login-dialog-spaces">------</div>
      {{ 'LOGIN.alt' | translate }}
      <div class="login-dialog-spaces">------</div>
    </div>
    <div class="login-dialog__app-login-container" *ngIf="enablePhoneLogin">
      <app-login (loggedIn)="onLogin()" (isLoading)="onLoading($event)"></app-login>
    </div>
    <div *ngIf="!showNoLoginMethods">
      <p class="privacy-policy-message" [innerHTML]="'LOGIN.privacyPolicyMessage' | translate"></p>
    </div>
  </div>
</div>

import {environment} from '../environments/environment';
import Bugsnag from '@bugsnag/js';
import {toJSON} from './utils/toJson.utils';

export function bugsnagInit(appVersion: string) {
  const bugsnagConfig = {
    apiKey: '808336f72ccacd94a822b3f6e59df30e',
    releaseStage: environment.environmentName,
    enabledReleaseStages: environment.loggingEnabled ? [environment.environmentName] : [],
    appVersion,
  };

  Bugsnag.start({
    ...bugsnagConfig,
    onError(event) {
      if (event.originalError?.status == 0 && event.originalError?.name == 'HttpErrorResponse') {
        return false;
      } else if (event.originalError?.status == 20 && event.originalError?.name == 'AbortError') {
        return false;
      } else if (event.originalError?.message == 'Camera stopped. Please restart camera') {
        console.warn('Camera stopped; triggering window:restartCamera');
        return false;
      } else if (event.originalError?.message == 'Unable to start selected Camera' && document.visibilityState == 'hidden') {
        console.warn('Unable to start selected Camera');
        return false;
      } else if (event.originalError?.message == 'Get User Media not supported') {
        return false;
      } else if (event.originalError?.message == 'The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22') {
        return false;
      } else if (event.errors[0]?.stacktrace?.some((frame) => frame.file.includes('webkit-masked-url://hidden/'))) {
        return false;
      }

      if (
        (event.originalError?.name == 'HttpErrorResponse' && document.visibilityState == 'hidden') ||
        event.originalError?.name == 'AbortError'
      ) {
        // Request was likely aborted by the user
        event.severity = 'warning';
      } else if (event.originalError?.name == 'HttpErrorResponse' && event.originalError?.error) {
        event.addMetadata('Response', event.originalError.error);
      }

      try {
        event.setUser(JSON.parse(window.localStorage.getItem('bugsnag-anonymous-id') as string));
      } catch (e) {}

      try {
        // @ts-ignore
        const cortex = window.CortexDecoder;
        event.addMetadata('State', {
          'Page visibility': document.visibilityState.toUpperCase(),
          'CortexDecoder.CDCamera': cortex && cortex.CDCamera ? {
            stream: toJSON(cortex.CDCamera.stream ?? null),
            track: toJSON(cortex.CDCamera.track ?? null),
          } : null,
        });
      } catch (e) {}

      return true;
    },
  });

  // @ts-ignore NOTE: The performance package required Typescript >=4.5, so we'll need to import it from CDN instead
  if (typeof window.BugsnagPerformance !== 'undefined') {
    try {
      // @ts-ignore
      BugsnagPerformance.start({
        ...bugsnagConfig,
        settleIgnoreUrls: ['https://sessions.bugsnag.com', 'https://www.google-analytics.com/analytics.js', /.*\/googletagmanager\.com\//],
      });
    } catch (e) {}
  }

  // Log page hide / show to Bugsnag
  document.addEventListener('visibilitychange', () => {
    try {
      Bugsnag.leaveBreadcrumb('Page visibility changed to ' + document.visibilityState.toUpperCase());
    } catch (e) {}
  });
}

export function bugsnagStartSpan(name: string, options?: BugsnagSpanOptions) {
  try {
    return window.BugsnagPerformance?.startSpan(name, options);
  } catch (e) {
    return undefined;
  }
}

interface BugsnagSpanOptions {
  isFirstClass: boolean;
}

interface BugsnagPerformance {
  startSpan(name: string, options?: BugsnagSpanOptions): { end(): void };
}

declare global {
  interface Window {
    BugsnagPerformance?: BugsnagPerformance;
  }
}

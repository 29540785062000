<div class="toolbar-menu-closed" [ngClass]="{'toolbar-menu-open': isMenuOpen}">
  <div class="toolbar-content">
    <ng-container *ngIf="action">
      <ng-container *ngTemplateOutlet="action"
                    class="toolbar_back-button">
      </ng-container>
    </ng-container>
    <app-image-fading *ngIf="image != null && image != '', else titleBlock"
                      source="{{image}}" class="toolbar-title image"
                      [animateLoading]="false">
    </app-image-fading>
    <ng-template #titleBlock>
      <button *ngIf="showBackButton" class="privacy-policy_back-button" (click)="onBackClick()">
        <mat-icon class="privacy-policy_back_button_icon">keyboard_backspace</mat-icon>
      </button>
      <h2 class="toolbar-title" [ngStyle]="{color: profile?.primaryColor}">
        {{ title }}
      </h2>
    </ng-template>
  </div>
  <h4 *ngIf="subtitle != null" class="toolbar-subtitle"
      [ngStyle]="{color: profile?.primaryColor, marginTop: !image ? '-10px' : null}"
      [ngClass]="{'back-button-enabled': showBackButton}">
    {{ subtitle }}
  </h4>

  <div *ngIf="hasMenu" class="menu" [ngClass]="{'open': isMenuOpen}" [ngStyle]="{backgroundColor: profile?.primaryColor}">
    <app-menu *ngIf="isMenuOpen" [store]="store" (signOut)="toggleMenu()"></app-menu>
  </div>
  <div *ngIf="hasMenu" class="toolbar_menu_icon" (click)="toggleMenu()">
    <div id="nav-icon4" [ngClass]="{'open': isMenuOpen}">
      <span [ngStyle]="{background: !isMenuOpen ? profile?.primaryColor : profile?.primaryTextColor}"></span>
      <span [ngStyle]="{background: !isMenuOpen ? profile?.primaryColor : profile?.primaryTextColor}"></span>
      <span [ngStyle]="{background: !isMenuOpen ? profile?.primaryColor : profile?.primaryTextColor}"></span>
    </div>
    <div *ngIf="!isMenuOpen && showBadge" class="toolbar-badge"></div>
  </div>
</div>

<div class="root">
  <button [ngClass]="{'receipt-email__button': buttonStyle == 'button', 'receipt-email__link': buttonStyle == 'link'}"
          [ngStyle]="buttonStyle == 'button' ? {backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor} : {}"
          (click)="orderId && sendReceiptAsEmail(orderId!)">
    <ng-container *ngIf="buttonStyle == 'button'; else letterIcon"></ng-container>
    <span>{{ 'RECEIPT.receiveEmail' | translate }}</span>
  </button>
  <ng-template [ngIf]="receiptStatus">
    <button [ngClass]="{'receipt-email__button': buttonStyle == 'button', 'receipt-email__link': buttonStyle == 'link'}"
            [ngStyle]="buttonStyle == 'button' ? {backgroundColor: profile?.primaryColor, color: profile?.primaryTextColor} : {}"
            (click)="orderId && sendReceiptAsGiftEmail(orderId!)">
      <ng-container *ngIf="buttonStyle == 'button'; else letterIcon"></ng-container>
      <span>{{ 'RECEIPT.giftEmail' | translate }}</span>
    </button>
  </ng-template>
</div>

<ng-template #letterIcon>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
    <mask id="mask0_2400_4472" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
      <rect x="0.5" width="24" height="24" fill="#D9D9D9"/>
    </mask>
    <g mask="url(#mask0_2400_4472)">
      <path
        d="M4.8077 19.5C4.30257 19.5 3.875 19.325 3.525 18.975C3.175 18.625 3 18.1974 3 17.6923V6.3077C3 5.80257 3.175 5.375 3.525 5.025C3.875 4.675 4.30257 4.5 4.8077 4.5H20.1923C20.6974 4.5 21.125 4.675 21.475 5.025C21.825 5.375 22 5.80257 22 6.3077V17.6923C22 18.1974 21.825 18.625 21.475 18.975C21.125 19.325 20.6974 19.5 20.1923 19.5H4.8077ZM20.5 7.44225L12.9865 12.2519C12.9096 12.2955 12.8301 12.3298 12.7481 12.3548C12.666 12.3798 12.5833 12.3923 12.5 12.3923C12.4166 12.3923 12.334 12.3798 12.2519 12.3548C12.1699 12.3298 12.0904 12.2955 12.0135 12.2519L4.49998 7.44225V17.6923C4.49998 17.782 4.52883 17.8557 4.58653 17.9134C4.64423 17.9711 4.71795 18 4.8077 18H20.1923C20.282 18 20.3557 17.9711 20.4134 17.9134C20.4711 17.8557 20.5 17.782 20.5 17.6923V7.44225ZM12.5 11L20.3461 5.99998H4.65383L12.5 11ZM4.49998 7.673V6.52978V6.55957V6.52785V7.673Z"
        fill="currentColor"/>
    </g>
  </svg>
</ng-template>

import {Injectable} from '@angular/core';
import {OrderProvider} from '../transport/order.provider';
import {CartResponse} from '../transport/models/order/cart.response';
import {Cart} from './models/order/cart';
import {CartUtils} from '../utils/cart.utils';
import {Order} from './models/order/order';
import {Page, Paginated} from '../transport/models/paginated';
import {LocalStorageService} from 'ngx-webstorage';
import {OrderLineRelationType} from './models/order/order-line-relation';
import {GateCode} from './models/order/gate-code';
import {PaymentService} from './payment.service';
import {InspectionStatus} from './models/order/inspection-status';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  readonly LAST_KNOWN_ORDER_KEY = 'last-known-order-id';
  readonly MOST_RECENT_PURCHASE_KEY = 'most-recent-purchase';
  readonly ORDERS_INSPECTED_KEY = 'orders-inspected';

  constructor(private orderProvider: OrderProvider, private localStorageService: LocalStorageService) {
  }

  async getOrders(page?: Page): Promise<Paginated<Order>> {
    return this.orderProvider.getOrders(page).toPromise();
  }

  async getOrder(orderId: string): Promise<Order> {
    return this.orderProvider.getOrder(orderId).toPromise();
  }

  async getOrderGateCode(orderId: string): Promise<GateCode> {
    return this.orderProvider.getOrderGateCode(orderId).toPromise();
  }

  async validateCart(cart: Cart): Promise<CartResponse> {
    this.wipeAnyRelatedProductOrderLines(cart);

    try {
      // We need to await the request here in order to catch it
      return await this.orderProvider.getCart(CartUtils.toRequest(cart)).toPromise();
    } catch (errorResponse) {
      // Try to recover
      try {
        await PaymentService.errorHandler(errorResponse, {
          orderIsPaid: () => {
            // Order has already been paid; reset order and order lines IDs:
            cart.orderId = undefined;
            cart.orderLines.forEach(orderLine => orderLine.id = '*');
          },
          productNotFound: () => {
            // TODO Implement handling for product not found
          },
          dimensionOutOfStock: () => {
            // TODO Implement handling for DimensionOutOfStock
          },
        });
      } catch (rethrownErrorResponse) {
        if (rethrownErrorResponse.status == 400) {
          // One last attempt, if this is an EG edge-case where an item corrupts the cart for some reason
          this.removeLastOrderFromCart(cart);
        } else {
          throw rethrownErrorResponse;
        }
      }

      return this.orderProvider.getCart(CartUtils.toRequest(cart)).toPromise();
    }
  }

  private wipeAnyRelatedProductOrderLines(cart: Cart) {
    cart.orderLineRelations.forEach(relation => {
      if (relation.type == OrderLineRelationType.Product) {
        const relatedOrderLineIndex = cart.orderLines.findIndex(ol => ol.id == relation.relatedOrderLineId);

        if (relatedOrderLineIndex >= 0) {
          cart.orderLines.splice(relatedOrderLineIndex, 1);
        }
      }
    });
  }

  private removeLastOrderFromCart(cart: Cart) {
    cart.orderLines.pop();
  }

  setLastKnownOrder(orderId: string | undefined) {
    this.localStorageService.store(this.LAST_KNOWN_ORDER_KEY, orderId);
  }

  getLastKnownOrder() {
    return this.localStorageService.retrieve(this.LAST_KNOWN_ORDER_KEY);
  }

  async transferOrder(orderId: string, newCustomerId: string) {
    return this.orderProvider.transferOrder(orderId, newCustomerId).toPromise();
  }

  clearLastKnownOrder() {
    this.localStorageService.clear(this.LAST_KNOWN_ORDER_KEY);
  }

  setMostRecentPurchase(storeChainId: string, timestamp: number) {
    this.localStorageService.store(`${this.MOST_RECENT_PURCHASE_KEY}_${storeChainId}`, timestamp);
  }

  hasRecentPurchase(storeChainId: string) {
    const timeLimit = 60 * 1000 * 15; // 15 minutes
    const timestamp = this.localStorageService.retrieve(`${this.MOST_RECENT_PURCHASE_KEY}_${storeChainId}`) as number;

    return timestamp < Date.now() && (timestamp + timeLimit) > Date.now();
  }

  clearMostRecentPurchase(storeChainId: string) {
    this.localStorageService.clear(`${this.MOST_RECENT_PURCHASE_KEY}_${storeChainId}`);
  }

  setOrderDelivered(orderId: string) {
    return this.orderProvider.setOrderDelivered(orderId).toPromise();
  }

  getInspectionStatus(orderId: string): Promise<InspectionStatus> {
    return this.orderProvider.getInspectionStatus(orderId).toPromise();
  }

  validateInspectionCode(orderId: string, code: string) {
    return this.orderProvider.validateInspectionCode(orderId, code).toPromise();
  }

  setOrderInspected(orderId: string) {
    const orders = this.localStorageService.retrieve(this.ORDERS_INSPECTED_KEY) as string[] ?? [];
    orders.push(orderId);
    this.localStorageService.store(this.ORDERS_INSPECTED_KEY, orders);
  }

  isOrderInspected(orderId: string) {
    const orders = this.localStorageService.retrieve(this.ORDERS_INSPECTED_KEY) as string[] ?? [];
    return orders.includes(orderId);
  }

  clearOrdersInspected() {
    this.localStorageService.clear(this.ORDERS_INSPECTED_KEY);
  }
}

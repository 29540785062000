import {Component, Input, OnInit} from '@angular/core';
import {EmailDialog} from '../../dialogs/email/email.dialog';
import {EmailService} from '../../domain/email.service';
import {MatDialog} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {StoreService} from '../../domain/store.service';
import {Store} from '../../domain/models/store/store';
import {StoreProfile} from '../../domain/models/store/store-profile';

@Component({
  selector: 'app-receipt-email-button',
  templateUrl: './receipt-email-button.component.html',
  styleUrls: ['./receipt-email-button.component.sass'],
})
export class ReceiptEmailButtonComponent implements OnInit {
  @Input() store!: Store;
  @Input() orderId?: string;
  @Input() useGiftReceipt?: boolean;
  @Input() buttonStyle: 'button' | 'link' = 'button';
  receiptStatus = false;
  profile?: StoreProfile;

  constructor(private emailService: EmailService,
              private dialog: MatDialog,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private storeService: StoreService,
  ) {
  }

  async ngOnInit() {
    if (!this.orderId) {
      throw new Error('Attribute \'orderId\' is required');
    }

    this.profile = this.store.storeProfile;

    if (this.useGiftReceipt == true) {
      const receiptStatus = await this.storeService.isGiftReceiptEnabled(this.store.handle);
      if (receiptStatus) {
        this.receiptStatus = true;
      }
      return;
    }
  }

  async sendReceiptAsEmail(orderId: string) {
    const email = await this.dialog.open(EmailDialog, {data: {profile: this.profile}}).afterClosed().toPromise();
    if (email) {
      const result = await this.emailService.sendReceipt(orderId, email).catch(_ => undefined);
      if (result === undefined) {
        const message = await this.translateService.get('DIALOG.EMAIL.error').toPromise();
        this.toastr.error(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
      } else {
        const message = await this.translateService.get('DIALOG.EMAIL.success').toPromise();
        this.toastr.success(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
      }
    }
  }

  async sendReceiptAsGiftEmail(orderId: string) {
    const email = await this.dialog.open(EmailDialog, {data: {profile: this.profile}}).afterClosed().toPromise();
    if (email) {
      const result = await this.emailService.sendGiftReceipt(orderId, email).catch(_ => undefined);
      if (result === undefined) {
        const message = await this.translateService.get('DIALOG.EMAIL.error').toPromise();
        this.toastr.error(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
      } else {
        const message = await this.translateService.get('DIALOG.EMAIL.success').toPromise();
        this.toastr.success(message, undefined, {timeOut: 3000, easeTime: 100, positionClass: 'toast-bottom-center'});
      }
    }
  }
}

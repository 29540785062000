import {environment} from '../../environments/environment';

export class DevUtils {
  static isTestEnv(): boolean {
    return ['dev', 'test', 'staging'].includes(environment.environmentName);
  }

  static assert(condition: boolean, message?: string, onFail?: (msg: string) => void): asserts condition {
    if (DevUtils.isTestEnv() && !condition) {
      const errorMessage = 'Assertion failed: ' + message || 'Assertion failed';
      // eslint-disable-next-line no-console
      console.error(errorMessage);
      onFail?.(errorMessage);
    }
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PaginationHandler} from '../../../domain/pagination/pagination.handler';
import {OrderService} from '../../../domain/order.service';
import {Order} from '../../../domain/models/order/order';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {OrderUtils} from '../../../utils/order.utils';
import {StoreService} from '../../../domain/store.service';
import {CustomerService} from '../../../domain/customer.service';
import {Store} from '../../../domain/models/store/store';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.sass'],
})
export class OrderListComponent implements OnInit {
  readonly Object = Object;

  store!: Store;
  handler?: PaginationHandler<Order>;
  isChrome = /chrome/i.test(navigator.userAgent);
  groupedOrders: Record<string, Order[]> = {};
  recentOrderIds: string[] = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private orderService: OrderService,
              private translateService: TranslateService,
              private titleService: Title,
              private storeService: StoreService,
              private customerService: CustomerService,
  ) {
  }

  async ngOnInit() {
    await this.storeService.preserveContextInUrl();

    const storeHandle = this.route.parent!.firstChild!.snapshot.paramMap.get('id')!;
    this.store = await this.storeService.getStore(storeHandle);
    this.customerService.initCustomerFromAuth(this.store.storeChain.id);

    this.handler = new PaginationHandler<Order>(async () => this.orderService.getOrders());
    this.handler.getNext().then(() => this.splitOrders(this.handler!.data));

    this.translateService.get('MENU.myPurchases').toPromise()
      .then(title => this.titleService.setTitle(`${title} | Fygi`));
  }

  // FIXME This does not work because the applied element is not the one with the overflow scroll. See SCAN-2732
  async onScrolled(event: any) {
    if (this.handler?.isBusy) {
      return;
    }
    const bottomTriggerPoint = 150;
    if (event.target.offsetHeight + event.target.scrollTop >= (event.target.scrollHeight - bottomTriggerPoint)) {
      if (this.handler?.hasNext()) {
        await this.handler.getNext();
        this.splitOrders(this.handler.data);
      }
    }
  }

  splitOrders(orders: Order[]) {
    for (const order of orders) {
      const paidAtDate = OrderUtils.getPaidAtDate(order);
      let dateKey = '';

      if (paidAtDate && this.isToday(paidAtDate)) {
        dateKey = '_today_';
      } else {
        dateKey = paidAtDate?.toLocaleString(undefined, {year: 'numeric', month: 'long', day: '2-digit'}) ?? '-';
      }

      if (!this.groupedOrders[dateKey]) {
        this.groupedOrders[dateKey] = [];
      }

      this.groupedOrders[dateKey].push(order);

      const recentMaxAge = 60 * 1000 * 15; // 15 minutes

      if (paidAtDate && (paidAtDate.getTime() + recentMaxAge) > Date.now()) {
        this.recentOrderIds.push(order.id);
      }
    }
  }

  private isToday(date: Date): boolean {
    const today = new Date();
    return date.getDate() == today.getDate()
      && date.getMonth() == today.getMonth()
      && date.getFullYear() == today.getFullYear();
  }

  async openReceipt(orderId: string) {
    await this.router.navigate([`/store/${this.store.handle}/orders/${orderId}`], {
      relativeTo: this.route,
      queryParamsHandling: 'preserve',
    });
  }
}

<div class="privacy-policy_root">
  <div class="privacy-policy_back-div">
    <button class="privacy-policy_back-button" (click)="onBackClick()">
      <mat-icon class="privacy-policy_back_button_icon">arrow_back</mat-icon>
    </button>
  </div>
  <ng-container *ngIf="!body">
    <li *ngFor="let x of [null, null, null, null, null, null, null, null, null]">
      <div style="background: #f4f4f4"></div>
      <div>
        <app-gradient-loader></app-gradient-loader>
        <app-gradient-loader style="width: 90%; margin: 10px 0;"></app-gradient-loader>
      </div>
    </li>
  </ng-container>

  <div [innerHTML]="body">
  </div>
  <div *ngIf="body">
    <a class="privacy-policy_delete-button" href="mailto:support@fygi.no?subject=Delete Customer: {{this.customerId}}
       &body=I would like to delete my data, my customerId is {{this.customerId}}">
      {{'POLICY.deleteUser' | translate }}
    </a>
  </div>
</div>

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CustomerService} from '../../domain/customer.service';

@Component({
  selector: 'app-vipps-login[storeChainId]',
  templateUrl: './vipps-login.component.html',
  styleUrls: ['./vipps-login.component.sass'],
})
export class VippsLoginComponent {
  @Input('storeChainId') storeChainId!: string;
  @Output() loggedIn = new EventEmitter();
  @Output() isLoading = new EventEmitter<boolean>();
  language?: string;

  constructor(private customerService: CustomerService,
              translateService: TranslateService,
  ) {
    this.language = translateService.getBrowserLang().toLocaleLowerCase();
  }

  async login() {
    this.isLoading.emit(true);

    const url = new URL(window.location.href);
    url.searchParams.append('chainId', this.storeChainId);
    const returnPath = url.pathname + url.search;

    const response = await this.customerService
      .customerVippsInitialize(this.storeChainId, returnPath)
      .finally(() => this.isLoading.emit(false));

    window.location.href = response.startLoginAddress;
  }
}

import {HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {CustomerService} from './domain/customer.service';
import {LoginDialogState} from './dialogs/login-dialog/login.dialog';
import {decodeJwt} from 'jose';
import {environment} from '../environments/environment';
import {httpUtils} from './utils/http.utils';
import Bugsnag from '@bugsnag/js';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  private isRefreshingToken = false;
  private readonly baseUrl: string;

  constructor(private toastr: ToastrService,
              private customerService: CustomerService,
  ) {
    this.baseUrl = new URL(environment.apiUrl).origin;
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.includes(this.baseUrl) && !this.isRefreshingToken) {
      const storeChainId = this.customerService.currentStoreChainId;

      if (storeChainId) {
        const jwt = this.customerService.getAndValidateToken(storeChainId);

        if (jwt === false) {
          const {CustomerId, Anonymous} = decodeJwt(this.customerService.retrieveToken(storeChainId));

          if (Anonymous == 'True') {
            req = this.refreshToken(storeChainId, CustomerId as string, req, next);
          } else if (Anonymous == 'False' && !req.context.get(HTTP_SUPPRESS_LOGIN_DIALOG)) {
            this.customerService.openLoginDialog(storeChainId, LoginDialogState.LoginExpired);

            return next.handle(req);
          }
        } else if (jwt !== null) {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${jwt}`,
            },
          });
        }
      }

      return next.handle(req).pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            this.handleResponseError(error, storeChainId, req.context.get(HTTP_SUPPRESS_LOGIN_DIALOG));
          }
          return throwError(error);
        }),
      );
    }

    return next.handle(req);
  }

  private refreshToken(storeChainId: string, customerId: string, req: HttpRequest<unknown>, next: HttpHandler) {
    this.isRefreshingToken = true;
    this.customerService.customerAnonymousAuthenticate(storeChainId, customerId)
      .then(() => {
        req = req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.customerService.retrieveToken(storeChainId)}`,
          },
        });

        return next.handle(req);
      })
      .finally(() => this.isRefreshingToken = false);

    return req;
  }

  private handleResponseError(error: HttpErrorResponse, storeChainId: string | null, suppressLoginDialog: boolean) {
    const http = httpUtils(error);
    if (http.isStatus(401, 403) && !suppressLoginDialog) {
      if (storeChainId && this.customerService.isAnonymousUser(storeChainId) === false) {
        this.customerService.openLoginDialog(storeChainId, LoginDialogState.LoginRequired);
      } else if (!storeChainId) {
        Bugsnag.notify(
          {name: 'Failed to authenticate', message: 'Missing store chain ID'},
          event => event.addMetadata('Response', error),
        );
        this.toastr.error(`Failed to authenticate. Click to refresh.`, 'Error', {
          disableTimeOut: true,
          closeButton: true,
          easeTime: 100,
          positionClass: 'toast-bottom-center',
        }).onHidden.subscribe(() => window.location.reload());
      }
    } else if (http.isNotStatus(404, 423, 0)) {
      this.toastr.warning(`Something went wrong (${http.status})`, 'Error', {
        timeOut: 8000,
        easeTime: 100,
        positionClass: 'toast-bottom-center',
      });
    }
  }
}

export const HTTP_SUPPRESS_LOGIN_DIALOG = new HttpContextToken<boolean>(() => false);

import {Injectable} from '@angular/core';
import {EmailReceiptRequest} from '../transport/models/email/email-receipt.request';
import {EmailReceiptResponse} from '../transport/models/email/email-receipt.response';
import {EmailProvider} from '../transport/email.provider';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private emailProvider: EmailProvider) {
  }

  async sendReceipt(orderId: string, recipient: string): Promise<EmailReceiptResponse> {
    const request = new EmailReceiptRequest(orderId, recipient);
    return this.emailProvider.sendReceipt(request).toPromise();
  }

  async sendGiftReceipt(orderId: string, recipient: string): Promise<EmailReceiptResponse> {
    const request = new EmailReceiptRequest(orderId, recipient);
    return this.emailProvider.sendGiftReceipt(request).toPromise();
  }
}
